<template>
  <v-container fluid v-if="this.$route.name === 'order-details'">
    <v-row dense align="center">
      <v-col cols="12">
        <vbl-breadcrumbs v-if="order" :crumbs="crumbs"></vbl-breadcrumbs>
        <order-details
          :order="order"
          :credentials="credentials"
          :loading="loading"
        ></order-details>
      </v-col>
      <v-col cols="12" v-for="child in children" :key="child.id">
        <order-details
          :order="child"
          :credentials="credentials"
          :loading="loading"
        ></order-details>
      </v-col>
    </v-row>
  </v-container>
  <v-container fluid v-else>
    <v-row dense align="center">
      <v-col cols="12">
        <order-locator
          :needEmail="needEmail"
          :login="login"
          :invalid="invalid"
          :mismatch="mismatch"
          :orderId="iOrderId"
          @get-bag-click="getBag"
          :loading="loading"
        ></order-locator>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-card color="color1 color1Text--text">
          <v-card-title>
            <h2>Did you know?</h2>
          </v-card-title>
          <v-card-text class="color1Text--text">
            You can create an account by clicking sign in at the top of the page.
            Plus, if you sign in before you pay for any registrations, you will be able to easily find them in your order history.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import { mapGetters } from 'vuex'
import OrderDetails from '@/components/Cart/OrderDetails.vue'
import OrderLocator from '@/components/Cart/OrderLocator.vue'

export default {
  props: ['orderId'],
  data () {
    return {
      orders: null,
      iOrderId: null,
      email: null,
      loading: false,
      login: [],
      needEmail: [],
      invalid: [],
      mismatch: [],
      from: null,
      credentials: null
    }
  },
  computed: {
    ...mapGetters(['user']),
    order () {
      return this.orders && this.orders[0]
    },
    children () {
      return this.orders && this.orders.filter(f => f.id !== this.order.id)
    },
    crumbs () {
      var x = (this.user && this.from && this.from.name !== 'order-lookup') ? [
        {
          to: { name: 'my-orders', params: { orderId: this.order.id } },
          text: 'My Orders'
        }
      ] : [
        {
          to: { name: 'order-lookup', params: { orderId: this.order.id } },
          text: 'Order Lookup'
        }
      ]

      x.push({
        text: this.order.id,
        disabled: true
      })

      return x
    }
  },
  methods: {
    getBag (dto) {
      const orderId = dto.id
      const email = dto.email
      this.showError = false
      this.loading = true
      this.$VBL.cart.getBag(dto)
        .then(r => {
          this.orders = r.data
          this.credentials = dto
          this.reset()
        })
        .catch(err => {
          if (err.response.data === 'NeedEmail') {
            this.needEmail.push(orderId)
          } else if (err.response.data === 'Login') {
            this.login.push(orderId)
          } else if (err.response.data === 'Invalid Id') {
            this.invalid.push({ id: orderId, error: err.response.data })
          } else {
            this.mismatch.push({ id: orderId, email: email, error: err.response.data })
            this.needEmail.push(orderId)
          }
          if (this.$route.name === 'order-details') this.$router.push({ name: 'order-lookup' })
        })
        .finally(() => {
          this.loading = false
        })
    },
    reset () {
      this.needEmail = []
      this.invalid = []
      this.mismatch = []
    }
  },
  created () {
    if (this.$route.params.orderId) this.iOrderId = this.$route.params.orderId
    if (this.$route.params.email) this.email = this.$route.params.email
    if (this.$route.name === 'order-details') this.getBag({ id: this.iOrderId, email: this.email })
  },
  components: {
    OrderDetails,
    OrderLocator
  },
  watch: {
    user: function (val) {
      if (val) {
        this.reset()
      }
    },
    order: function (val) {
      if (val && val.id && this.$route.name !== 'order-details') {
        this.$router.push({ name: 'order-details', params: { orderId: val.id } })
      }
    }
  },
  beforeRouteEnter: function (to, from, next) {
    next(vm => {
      vm.from = from
    })
  }
}
</script>
